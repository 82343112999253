import MenuIcon from '@mui/icons-material/Menu';
import { Grid, Hidden } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useSessao } from '../../redux/sessao/sessaoHooks';
import CarrinhoPedido from '../../Pages/Pedido/CarrinhoPedido';

type Props = {
  setOpen: (x: boolean) => void;
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));

const Header: React.FC<Props> = (props) => {
  const { setOpen } = props;

  const [sessao] = useSessao();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const corEmpresa = '#2196f3';

  return (
    <div>
      <AppBar
        position="relative"
        // open={open}
        style={{ padding: '5px', backgroundColor: corEmpresa }}
      >
        <Grid container alignItems={'center'}>
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginLeft: 1
              }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>

          <Hidden mdDown>
            <Typography
              variant="h6"
              noWrap
              component="div"
              style={{ flexGrow: 1, textAlign: 'center' }}
            >
              {sessao.empresa?.sigla}
            </Typography>
          </Hidden>

          <Hidden mdUp>
            <Grid item xs>
              <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'center' }}>
                {sessao.empresa?.sigla}
              </Typography>
            </Grid>
          </Hidden>

          {sessao?.empresa?.clientePodeFazerPedido && <CarrinhoPedido />}
        </Grid>
      </AppBar>
    </div>
  );
};

export default Header;
