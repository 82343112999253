import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useSessao } from '../../redux/sessao/sessaoHooks';

type Props = {
  open: boolean;
};

const Logout: React.FC<Props> = (props) => {
  const { open } = props;
  const [sessao, , logout] = useSessao();

  const corEmpresa = sessao.empresa?.corPrincipal ?? '#2196f3';

  return (
    <>
      {!open ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            whiteSpace: 'normal',
            margin: '0% 5%',
            marginBottom: '10px'
          }}
        >
          <LogoutIcon style={{ color: 'red' }} />
        </div>
      ) : (
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              '&:hover': {
                '& .MuiIconButton-root': {
                  color: corEmpresa,
                  transform: 'scale(1.05)',

                  backgroundColor: 'transparent'
                }
              }
            }}
            onClick={() => {
              logout();
            }}
          >
            <ListItemIcon
              onClick={() => {
                logout();
              }}
            >
              <IconButton>
                <LogoutIcon
                  style={{ color: 'red' }}
                  onClick={() => {
                    logout();
                  }}
                />
                {open ? (
                  <Typography variant="h6" style={{ marginLeft: '5px', color: 'red' }}>
                    Sair
                  </Typography>
                ) : (
                  ''
                )}
              </IconButton>
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      )}
    </>
  );
};

export default Logout;
