import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { itensMenu } from '../Menu/ItensMenu';
import { Grid, Hidden } from '@mui/material';
import makeStyles from '@material-ui/core/styles/makeStyles';
import theme from '../../theme';
import { Link } from 'react-router-dom';
import SidebarItemCollapse from '../Menu/SideBarItemsCollapse';
import SidebarItem from '../Menu/SiderBarItem';
import { Divider } from '@material-ui/core';
import Logout from './Logout';

const drawerOpenWidth = 200;
const drawerClosedWidth = 60;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerOpenWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `${drawerClosedWidth}px`
});

const DrawerComponent = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerOpenWidth,
    flexShrink: 0,

    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

type Props = {
  open: boolean;
  setOpen: (x: boolean) => void;
};

const Drawer: React.FC<Props> = (props) => {
  const { open, setOpen } = props;

  const classes = useStyles();
  const corEmpresa = '#2196f3'; // TODO remover

  return (
    <>
      <Hidden mdDown>
        <DrawerComponent
          classes={{ paper: classes.paper }}
          variant="permanent"
          open={open}
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => {
            setOpen(false);
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingTop: 'env(safe-area-inset-top, 0px)',
              paddingBottom: 'env(safe-area-inset-bottom, 0px)'
              // backgroundColor: "blue"
            }}
          >
            <List style={{ marginTop: open ? '15px' : '80px' }}>
              {itensMenu
                .filter((item) => item.show)
                .map((route, index) =>
                  route.componente ? (
                    route.child ? (
                      <SidebarItemCollapse item={route} key={index} />
                    ) : (
                      <SidebarItem item={route} key={index} />
                    )
                  ) : null
                )}
            </List>
          </div>
          <Logout open={open} />
        </DrawerComponent>
      </Hidden>

      {open && (
        <DrawerComponent
          onClick={() => setOpen(false)}
          classes={{ paper: classes.paper }}
          variant="permanent"
          open={open}
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => {
            setOpen(false);
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingTop: 'env(safe-area-inset-top, 0px)',
              paddingBottom: 'env(safe-area-inset-bottom, 0px)'
            }}
          >
            <List style={{ marginTop: open ? '20px' : '60px' }}>
              {itensMenu
                .filter((item) => item.show)
                .map((item, index) => (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton
                        component={Link}
                        to={item.path}
                        sx={{
                          '&:hover': {
                            '& .MuiIconButton-root': {
                              color: corEmpresa,
                              transform: 'scale(1.05)',

                              backgroundColor: 'transparent'
                            }
                          }
                        }}
                      >
                        <ListItemIcon>
                          <IconButton>
                            {item.icon}
                            {open ? (
                              <Typography variant="h6" style={{ marginLeft: '5px' }}>
                                {item.label}
                              </Typography>
                            ) : (
                              ''
                            )}
                          </IconButton>
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                    <Grid item xs={12}>
                      <Divider light />
                    </Grid>
                  </>
                ))}
            </List>
          </div>
          <Logout open={open} />
        </DrawerComponent>
      )}
    </>
  );
};

export default Drawer;
const useStyles = makeStyles({
  paper: {
    // background: '#1976d2'
  },
  contentWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      marginTop: '10%'
    }
  },
  content: {
    flex: 1,

    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6)
    },

    marginTop: '10%'
  }
});
