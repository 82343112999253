import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useClienteLogado, useSessao } from '../../redux/sessao/sessaoHooks';
import CardsFinanceiro from './CardsFinanceiro';
import { toast } from 'react-toastify';
import ModalCadastrarSenha from '../LoginPage/ModalCadastrarSenha';
import Container from '../../Components/generic/Container';
import CardsPedido from './CardsPedido';

const InicioPage: React.FC = () => {
  const clienteLogado = useClienteLogado();

  const [sessao] = useSessao();
  const [openModal, setOpenModal] = useState(false);
  const [loading] = useState(false);

  const primeiroNome = clienteLogado?.nome.split(' ')[0] ?? '';
  const nomeCliente = `${primeiroNome.charAt(0).toUpperCase()}${primeiroNome
    .slice(1)
    .toLowerCase()}`;

  useEffect(() => {
    if (sessao.clienteUsuario?.senha === '') {
      setOpenModal(true);
    }
  }, []);

  const clientePodeFazerPedido = sessao?.empresa?.clientePodeFazerPedido;

  return (
    <Container page elevation={0} loading={loading}>
      <Grid container sx={{ marginTop: { xs: '-60px', md: '0' } }}>
        <Grid item xs={12} style={{ marginBottom: '5px' }}>
          <Typography fontSize={{ xs: 15, md: 25 }} style={{ textAlign: 'center' }}>
            Olá, {nomeCliente}!
          </Typography>
        </Grid>

        {clientePodeFazerPedido && <CardsPedido />}

        <CardsFinanceiro />

        <ModalCadastrarSenha
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
        />
      </Grid>
    </Container>
  );
};

export default InicioPage;
