import Modal from '@mui/material/Modal';
import { IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  width?: string;
  hideCloseButton?: boolean;
};

export default function ModalGeneric(props: ModalProps) {
  const { open, onClose, children, width, hideCloseButton } = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={() => onClose()}
        closeAfterTransition
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Paper
          elevation={5}
          style={{
            width: width ? width : '90vw',
            maxWidth: '800px',
            minWidth: 'min(97vw, 500px)',
            // maxWidth: 'min(100vw, 800px)',
            // maxHeight: '90%',
            marginTop: 10,
            marginBottom: 10,
            position: 'relative',
            paddingTop: 53,
            overflow: 'hidden' // Para garantir que o conteúdo não transborde
          }}
        >
          {hideCloseButton ? null : (
            <IconButton
              onClick={() => onClose()}
              style={{
                height: 53,
                width: 53,
                position: 'absolute',
                top: 0,
                right: 0
              }}
            >
              <CloseIcon />
            </IconButton>
          )}

          <div
            style={{
              maxHeight: 'calc(100% - 70px)',
              overflow: 'auto',
              paddingTop: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20
            }}
          >
            {children}
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
